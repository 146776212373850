// Global
$globalTypoSize: 12;
@import "functions/index";
$globalRadius: sptorem(4);
$globalBorderWidth: 1px;
$globalMarginBottom: 1rem;

// Fonts
@import "font-dosis/font-dosis";
$fontFamilyPrimary: 'Dosis regular', sans-serif;
$fontFamilySecondary: 'Montserrat', sans-serif;
$fontFamilyLoading: "flow-circular";
$textColorDefault: $colorPrimary;
$linkColor: $colorLink;

// Form
$inputBorderColor: $colorBlack;
$inputBorderWidth: $globalBorderWidth;
$inputHeight: pxtorem(24);

// Buttons
$buttonBorderWidth: $globalBorderWidth;


// Breakpoints
$phone-upper-boundary: 600px;
$tablet-portrait-upper-boundary: 900px;
$tablet-landscape-upper-boundary: 1200px;
$desktop-upper-boundary: 1800px;





