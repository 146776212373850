@import "../../assets/scss/colorsSimplified";

input,
textarea {
    padding: 0.7rem 1rem;
    background-color: $colorWhite;
    background-clip: padding-box;
    border: 1px solid $colorGrey;
    border-radius: 0.5rem;
    width: 100%;

    &:focus {
        background-color: $colorWhite;
        border-color: $colorPrimary;
        outline: none;
    }
}

.radio {
    margin: 0.5rem;
    input[type="radio"] {
        position: absolute;
        opacity: 0;
        width: 20%;

        + .radio-label {
            &:before {
                height: 1.5rem;
                width: 1.5rem;
                content: "";
                background: $colorWhite;
                border-radius: 100%;
                border: 1px solid $colorGrey;
                display: inline-block;
                position: relative;
                margin-right: 1em;
                vertical-align: middle;
                cursor: pointer;
                text-align: center;
                transition: all 250ms ease;
            }
        }
        &:checked {
            + .radio-label {
              color: $colorPrimary;
              font-weight: bold;
                &:before {
                    background-color: $colorPrimary;
                    border: 1px solid $colorPrimary;
                    box-shadow: 0 2px 4px 0 rgba(115,103,240,.4)!important;
                }
            }
        }
        &:focus {
            + .radio-label {
                &:before {
                    outline: none;
                }
            }
        }
        &:disabled {
            + .radio-label {
                // color: $alerts-default;
                &:before {
                    border-color: $colorGrey;
                    background: $colorGrey;
                    pointer-events: none;
                }
            }
        }
        &:required {
            + .radio-label:after {
                content: " *";
                color:$colorDanger;
            }
        }
        + .radio-label {
            &:empty {
                &:before {
                    margin-right: 0;
                }
            }
        }
    }
    &__error {
        input[type="radio"] + .radio-label:before {
            border-color: $colorDanger;
        }
    }
}
.radio-inline {
    display: flex;
    color: $colorSecondary;
    // justify-content: space-between;
}

.radio-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.date-picker_disabled input {
    background: #D1D1D1;
}

.date-picker{
    margin: auto;
    position: relative;
    display: inline-block;
    &__input{
        position: relative;
        clear: both;
        text-transform: capitalize;
        font-size: 1.3rem;
        color: $colorSecondary;
        text-align: center;
        input{
            width: 100%;
            height:30px;
            border: 1px solid $colorPrimary;
            background: $colorWhite;
            outline: none;
            padding-left: 10px;
            border-radius: 5px;

        }
        // &:before{
        //     font-family: 'Font Awesome 5 Free';
        //     font-weight: 900;
        //     content: "\f073";
        //     position: absolute;
        //     font-size: 1.3rem;
        //     top: 6px;
        //     right: 10px;
        // }
    }
    &_active{
        .date-picker__calendar{
            display: grid;
        }
        .date-picker__input{
           input{
            border-radius: 5px 5px 0 0;
           }
        }
    }
    

    &__calendar{
        display: none;
        background: white;
        grid-template-areas: "header" 
                             "content";
        width: 100%;
        grid-template-rows: 3rem 1fr;
        background: white;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
        z-index: 1000;
        &_header{
            grid-area: header;
            display: grid;
            grid-template-columns: 3rem 1fr 3rem;
            width: 100%;
            justify-items: center;
            align-items: center;
            height: 3rem;
            border-bottom: 1px solid $colorLightGrey;
            
            i{
                font-size: 2rem;
                cursor: pointer;

            }
            .month{
                font-size: 14px;
                font-weight: bolder;
            }
        }
        &_content{
            grid-area: content;
            width: 100%;
            height: auto;
            // height: 22rem;
            border-left: 1px solid $colorLightGrey;
            // border: 1px solid $colorLightGrey;
            border-top: none;
            border-bottom: none;

            .days{
                width: 100%;
                display:grid;
                grid-template-columns: repeat(7, 1fr);
                clear: both;
                border-bottom: 1px solid $colorLightGrey;
                color: $colorPrimary;
                font-weight: bold;
                // background: $inputs-bcg;

                
                &__item{
                    display: flex;
                    height: 2rem;
                    border-right: 1px solid $colorLightGrey;
                    justify-content: center;
                    align-items: center;
                    font-size: 1.2rem;
                }
            }
            .date{
                width: 100%;
                display:grid;
                grid-template-columns: repeat(7, 1fr);
                
                &__item{
                    display: flex;
                    border-right: 1px solid $colorLightGrey;
                    justify-content: center;
                    align-items: center;
                    height: 2rem;
                    border-bottom:1px solid $colorLightGrey;
                    position: relative;
                    cursor: pointer;
                    font-size: 1rem;

                    &_selected{
                        background: $colorPrimary;
                        color: $colorWhite;
                    }
                    &_disabled{
                        background: $colorGrey;
                        opacity: 0.75;
                        pointer-events: none;
                    }
                    &_pastDay{
                        background: repeating-linear-gradient(
                          20deg,
                          $colorLightGrey,
                          $colorLightGrey 6px,
                          $colorGrey 6px,
                          $colorGrey 12px
                        );
                    }
                    &_today{
                        &:before{
                            content:"";
                            position: absolute;
                            // background: $primary-white;
                            border: black 1px solid;
                            // z-index: 8;
                            opacity: 0.4;
                            height: 1.5rem;
                            width: 1.5rem;
                            border-radius: 50%;

                        }
                    }
                }
            }
            
        }
    }
}

.modal{
    .date-picker{
        &_active{
            z-index: 2;
        }
    }
}
