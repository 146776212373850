/* You can add global styles to this file, and also import other style files */
@import "./app/libs/assets/scss/index";
@import './app/libs/assets/scss/colorsSimplified';
// $box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);

gridster .gridster-column{
    border-left: 1px solid $colorPrimary !important;
    border-right: 1px solid $colorPrimary !important;
    // display: block !important;
}
gridster .gridster-row{
    border-bottom: 1px solid $colorPrimary !important;
    border-top: 1px solid $colorPrimary !important;
}

gridster{
    background: #f8f8f8 !important;
}
// gridster-item{
//     box-shadow: $box-shadow !important;
// }
// gridster.display-grid .gridster-column, gridster.display-grid .gridster-row{
//     display: block !important;
// }


.invalid-input input{
    border: 1px solid $colorDanger;
}

.invalid-input select{
    border: 1px solid $colorDanger;
}

.invalid-label {
    margin-top: .25rem;
    margin-left: 0.25rem;
    color: $colorDanger; 
}

#count-config .date-picker__input{
    border: 1px solid $colorGrey;
    padding: 0.5rem;
    border-radius: 5px;
    cursor: pointer;
}




