@mixin typo-primary(
	$font-size: 1rem,
	$font-weight: 400,
	$color: $colorPrimary,
	$line-height: 1.54,
	$font-style: normal
) {
	font-family: $fontFamilyPrimary;
	font-size: $font-size;

	@if $font-weight==lighter {
		font-weight: 300;
	}

	@if $font-weight !=lighter {
		font-weight: $font-weight;
	}

	color: $color;
	line-height: $line-height;
	font-style: $font-style;
}

@mixin typo-secondary(
	$font-size: 1rem,
	$font-weight: 400,
	$color: $colorBlackGrey,
	$line-height: 1.54,
	$font-style: normal
) {
	font-family: $fontFamilySecondary;
	font-size: $font-size;

	@if $font-weight==lighter {
		font-weight: 300;
	}

	@if $font-weight !=lighter {
		font-weight: $font-weight;
	}

	color: $color;
	line-height: $line-height;
	font-style: $font-style;
}

html {
	font-family: $fontFamilyPrimary;
}

.typo {
	font-family: $fontFamilyPrimary;
	@include typo-primary;

	&_secondary {
		font-family: $fontFamilySecondary;
		@include typo-secondary;
	}

	&_loading {
		font-family: $fontFamilyLoading;
	}
}

// h1{
// 	font-family:'Dosis extra bold';
// 	font-size: sptorem(30) !important;
// }
// h2{
// 	font-family:'Dosis bold';
// 	text-transform: uppercase;
// 	font-size: sptorem(26) !important;
// }
// h3{
// 	font-family:'Dosis semi bold';
// 	font-size: sptorem(24) !important;
// }

p {
    font-family:'Dosis regular';
	font-size: 12px !important;
}

.required{
	:before{
		content :"*";
		color: $colorDanger;
	}
}