@use "sass:math";

html {
	font-weight: 400;
	font-size: 12px;
	@include letterspacing(.5);
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-top: 0;
	margin-bottom: $globalMarginBottom;
}

h1,
.h1 {
	@extend .typo_secondary;
	font-weight: 300;
	font-size: sptorem(30);
	font-weight: bold;
	letter-spacing: .2rem;
}

h2,
.h2 {
	@extend .typo_secondary;
	font-weight: 300;
	font-size: sptorem(26);
	letter-spacing: .2rem;
	font-weight: bold;
}

h3,
.h3 {
	@extend .typo_secondary;
	font-weight: 400;
	font-size: sptorem(22);
	letter-spacing: .2rem;
	font-weight: bold;
}

h4,
.h4 {
	@extend .typo_secondary;
	font-weight: 400;
	font-size: sptorem(20);
	letter-spacing: .2rem;
}

h5,
.h5 {
	@extend .typo_secondary;
	font-weight: 400;
	font-size: sptorem(18);
	letter-spacing: .2rem;
}

h6,
.h6 {
	@extend .typo_secondary;
	font-weight: 500;
	font-size: sptorem(16);
	letter-spacing: .2rem;
}

.subtitle,
.subtitle-1,
[subtitle],
[subtitle-1] {
	font-weight: 400;
	font-size: sptorem(16);

	@include letterspacing(.15);

	margin-bottom: math.div($globalMarginBottom, 2);
}

.subtitle-2,
[subtitle-2] {
	font-weight: 500;
	font-size: sptorem(14);

	@include letterspacing(.1);

	margin-bottom: math.div($globalMarginBottom, 2);
}

.body,
.body-1,
[body],
[body-1] {
	font-weight: 400;
	font-size: sptorem(16);

	@include letterspacing(.5);
}

.body-2,
[body-2] {
	font-weight: 400;
	font-size: sptorem(14);

	@include letterspacing(.25);
}

.button,
[button] {
	font-weight: 500;
	font-size: sptorem(14);

	@include letterspacing(.75);
}

.caption,
[caption] {
	font-weight: 400;
	font-size: sptorem(12);

	@include letterspacing(.4);
}

.overline,
[overline] {
	text-transform: uppercase;
	font-weight: 400;
	font-size: sptorem(10);

	@include letterspacing(1.5);

	margin-bottom: math.div($globalMarginBottom, 6);
}
