@font-face {
    font-family: 'Dosis regular';
    src:url('../../webfonts/dosis/Dosis-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis bold';
    src:url('../../webfonts/dosis/Dosis-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis extra bold';
    src:url('../../webfonts/dosis/Dosis-ExtraBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis light';
    src:url('../../webfonts/dosis/Dosis-ExtraLight.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis extra light';
    src:url('../../webfonts/dosis/Dosis-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis medium';
    src:url('../../webfonts/dosis/Dosis-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Dosis semi bold';
    src:url('../../webfonts/dosis/Dosis-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}