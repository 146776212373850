// Vars
// $colorPrimary:#7367f0;
$colorPrimary: #037EC1;
$colorLightPrimary: #7367f040;
$colorDarkPrimary: #27369c;
$colorSecondary: #82868B;

$colorBlackGrey: #757575;
$colorLightGrey: #cccccc;
$colorGrey: #ebe9f1;
$colorLightGrey: #f6f6f9;
$colorSuccess: #28C76F;
$colorDanger: #EA5455;
$colorInfo: #00CFE8;
$colorWarning: #FF9F43;
$colorLink: #00A1B5;
$colorBlack: #000000;
$colorWhite: #FFFFFF;



