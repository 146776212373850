html {
	font-size: $globalTypoSize + px;
	color: $textColorDefault;
}

body {
	margin: 0;
	background-color: #f8f8f8;
}

* {
	box-sizing: border-box;
}

