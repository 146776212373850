@use "sass:math";

.grid {
	display: inline-grid;
	width: 100%;
	grid-gap: 1rem;
	// background: red;
	
	&_marged-bottom{
		margin-bottom: 1rem;
	}
	&_overflow {
		overflow: auto;
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-bottom: math.div($globalMarginBottom, 2);
		margin-top: -1rem;

		&_x {
			overflow-x: auto;
		}

		&_y {
			overflow-y: auto;
		}
	}

	&_col {
		@for $i from 1 through 20 {

			&_#{$i} {
				grid-template-columns: repeat(#{$i}, 1fr);

				&.grid_overflow,
				&.grid_overflow_x {
					grid-template-columns: repeat(#{$i} , minmax(min-content, auto));
				}
			}
		}
	}

	&_col-md {
		@for $i from 1 through 20 {

			&_#{$i} {
				@include breakpoint(tablet-portrait-up) {
					grid-template-columns: repeat(#{$i}, 1fr);
				}
			}
		}
	}

	&_col-lg {
		@for $i from 1 through 20 {

			&_#{$i} {
				@include breakpoint(tablet-landscape-up) {
					grid-template-columns: repeat(#{$i}, 1fr);
				}
			}
		}
	}

	&_col-xl {
		@for $i from 1 through 20 {

			&_#{$i} {
				@include breakpoint(desktop-up) {
					grid-template-columns: repeat(#{$i}, 1fr);
				}
			}
		}
	}

	&_col-xxl {
		@for $i from 1 through 20 {

			&_#{$i} {
				@include breakpoint(big-desktop-up) {
					grid-template-columns: repeat(#{$i}, 1fr);
				}
			}
		}
	}

	&_col-sm {
		@for $i from 1 through 20 {

			&_#{$i} {
				@include breakpoint(phone-only) {
					grid-template-columns: repeat(#{$i}, 1fr);
				}
			}
		}
	}

	&_inline {
		display: inline-grid;
	}

	&__child {

		&_row {
			@for $i from 1 through 20 {

				&_#{$i} {
					grid-row: auto / span #{$i};
				}
			}
		}

		&_row-md {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(tablet-portrait-up) {
						grid-row: auto / span #{$i};
					}
				}
			}
		}

		&_row-lg {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(tablet-landscape-up) {
						grid-row: auto / span #{$i};
					}
				}
			}
		}

		&_row-xl {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(desktop-up) {
						grid-row: auto / span #{$i};
					}
				}
			}
		}

		&_row-xxl {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(big-desktop-up) {
						grid-row: auto / span #{$i};
					}
				}
			}
		}

		&_row-sm {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(phone-only) {
						grid-row: auto / span #{$i};
					}
				}
			}
		}

		&_col,
		&_fr {
			@for $i from 1 through 20 {

				&_#{$i} {
					grid-column: auto / span #{$i};
				}
			}
		}

		&_col-md,
		&_fr-md {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(tablet-portrait-up) {
						grid-column: auto / span #{$i};
					}
				}
			}
		}

		&_col-lg,
		&_fr-lg {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(tablet-landscape-up) {
						grid-column: auto / span #{$i};
					}
				}
			}
		}

		&_col-xl,
		&_fr-xl {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(desktop-up) {
						grid-column: auto / span #{$i};
					}
				}
			}
		}

		&_col-xxl,
		&_fr-xxl {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(big-desktop-up) {
						grid-column: auto / span #{$i};
					}
				}
			}
		}

		&_col-sm,
		&_fr-sm {
			@for $i from 1 through 20 {

				&_#{$i} {
					@include breakpoint(phone-only) {
						grid-column: auto / span #{$i};
					}
				}
			}
		}

		&_justify {

			&_end {
				justify-self: end;
			}

			&_start {
				justify-self: start;
			}

			&_center {
				justify-self: center;
			}
		}

		&_align {

			&_end {
				align-self: end;
			}

			&_start {
				align-self: start;
			}

			&_center {
				align-self: center;
			}
		}
	}

	&_overflow {
		overflow: auto;
		padding-top: 1rem;
		padding-bottom: 1rem;
		margin-bottom: math.div($globalMarginBottom, 2);
		margin-top: -1rem;

		&_x {
			overflow-x: auto;
		}

		&_y {
			overflow-y: auto;
		}
	}
}
.grid-gap{
	&__0{
		grid-gap: 0;
	}
	&__little{
		 grid-gap:0.2rem;
	}
}