@mixin breakpoint($range) {
	@if $range == phone-only {
		@media (max-width: #{$phone-upper-boundary - 1}) {
			@content;
		}
	}

	@else if $range == tablet-portrait-up {
		@media (min-width: $phone-upper-boundary) {
			@content;
		}
	}

	@else if $range == tablet-landscape-up {
		@media (min-width: $tablet-portrait-upper-boundary) {
			@content;
		}
	}

	@else if $range == desktop-up {
		@media (min-width: $tablet-landscape-upper-boundary) {
			@content;
		}
	}

	@else if $range == big-desktop-up {
		@media (min-width: $desktop-upper-boundary) {
			@content;
		}
	}

	@else if $range == tablet-landscape-down {
		@media (max-width: #{$tablet-portrait-upper-boundary - 1}) {
			@content;
		}
	}

	@else if $range == desktop-down {
		@media (max-width: #{$tablet-landscape-upper-boundary - 1}) {
			@content;
		}
	}

	@else if $range == big-desktop-down {
		@media (max-width: #{$desktop-upper-boundary - 1}) {
			@content;
		}
	}

	@else {
		@warn($range);
	}
}

// usage
// .my-box {
//     padding: 10px;
//     @include breakpoint(desktop-up) {
//         padding: 20px;
//     }
// }
